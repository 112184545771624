import { pumpApi } from '@libs/openapi';
import { defineModel } from 'foca';
import { OpenapiClientPump } from 'foca-openapi';

export type StepParams = OpenapiClientPump.GetTradeOchlQuery['step'];
export type OCHLItem = OpenapiClientPump.GetTradeOchlResponse[number];
export type ClmmOCHLItem = OpenapiClientPump.GetTradeClmmOchlResponse[number];
export type TradeHistoryItem = OpenapiClientPump.GetTradeHistoryResponse['data'][number];

const initialState: {
  clmmOchl: Record<string, ClmmOCHLItem[]>;
  ochl: Record<string, OCHLItem[]>;
  latestPrice: Record<string, OpenapiClientPump.GetTradeLatestPriceResponse>;
  tradeHistory: Record<string, OpenapiClientPump.GetTradeHistoryResponse>;
  present: OpenapiClientPump.GetTradePresentResponse;
} = {
  clmmOchl: {},
  ochl: {},
  latestPrice: {},
  tradeHistory: {},
  present: [],
};

export const pumpTradeModel = defineModel('pump-trade', {
  initialState,
  methods: {
    async fetchOCHL(opts: {
      tokenAddress: string;
      start: number;
      end?: number;
      step: StepParams;
      key: string;
      operation: 'merge' | 'replace';
    }) {
      const { tokenAddress, step, start, operation, key, end = Date.now() + 60_000 } = opts;
      const result = await pumpApi.trade.getTradeOchl({
        query: {
          tokenAddress,
          start,
          end,
          step,
        },
      });
      this.setState((state) => {
        if (operation === 'replace') {
          state.ochl[key] = result;
        } else {
          const prices = (state.ochl[key] ||= []);
          result.forEach((item) => {
            const index = prices.findIndex((price) => price.timestamp === item.timestamp);
            if (index >= 0) {
              prices[index] = item;
            } else {
              prices.push(item);
            }
          });
        }
      });
      return result;
    },
    async fetchLatestPrice(tokenAddress: string) {
      const result = await pumpApi.trade.getTradeLatestPrice({ query: { tokenAddress } });
      this.setState((state) => {
        state.latestPrice[tokenAddress] = result;
      });
    },
    async fetchClmmOCHL(opts: {
      clmmPoolId: string;
      start: number;
      step: StepParams;
      key: string;
      operation: 'merge' | 'replace';
    }) {
      const { clmmPoolId, step, start, operation, key } = opts;
      const result = await pumpApi.trade.getTradeClmmOchl({
        query: {
          clmmPoolId,
          start,
          end: Date.now() + 60_000,
          step,
        },
      });
      this.setState((state) => {
        if (operation === 'replace') {
          state.clmmOchl[key] = result;
        } else {
          const prices = (state.clmmOchl[key] ||= []);
          result.forEach((item) => {
            const index = prices.findIndex((price) => price.timestamp === item.timestamp);
            if (index >= 0) {
              prices[index] = item;
            } else {
              prices.push(item);
            }
          });
        }
      });
      return result;
    },
    async fetchTradeHistory(
      tokenAddress: string,
      page: number = 10,
      address: string = '',
      isBuy?: boolean,
    ) {
      const result = await pumpApi.trade.getTradeHistory({
        query: { page, address, tokenAddress, isBuy },
      });
      this.setState((state) => {
        state.tradeHistory[tokenAddress] = result;
      });
      return result;
    },
    async fetchTradePresent() {
      const result = await pumpApi.trade.getTradePresent({
        query: { size: 10 },
      });
      this.setState((state) => {
        state.present = result;
      });
      return result;
    },
  },
});
