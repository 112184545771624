import { useState, type FC } from 'react';
import styles from './index.module.scss';
import { type PoolFields, pumpSdk } from '@libs/pump';
import { useAddress } from '@hooks/use-address';
import { useTransaction } from '@hooks/use-transaction';
import Setting from '../select-slippage';
import { OpenapiClientPump } from 'foca-openapi';
import Buy from './buy';
import Sell from './sell';
import { Decimal } from 'turbos-clmm-sdk';
import { useBalance } from '@hooks/use-balances';
import { usePumpCalculateTokenAndThresholdAmount } from '@hooks/use-pump-amount';
import { useDebounce } from 'react-use';
import { walletConnectModel } from '@models/wallet-connect.model';
import Loading from '@ui/loading';
import { slippageModel } from '@models/slippage';
import { useModel } from 'foca';

interface TypeItem {
  label: 'buy' | 'sell';
  name: string;
}

const types: TypeItem[] = [
  {
    label: 'buy',
    name: 'Buy',
  },
  {
    label: 'sell',
    name: 'Sell',
  },
];

interface OwnerProps {
  pool: OpenapiClientPump.GetPoolsByTokenAddressResponse;
  poolFields: PoolFields;
  isBlack: boolean;
}

const BuyAndSell: FC<OwnerProps> = ({ pool, poolFields, isBlack }) => {
  const address = useAddress();
  const transact = useTransaction();
  const [isExact, setIsExact] = useState(true);
  const [type, setType] = useState<TypeItem['label']>('buy');
  const [amount, setAmount] = useState<undefined | string>();
  const slippage = useModel(slippageModel, (state) => state.slippage);

  const [finalAmount, setFinalAmount] = useState<undefined | string>();
  const [ratioAmount, setRatioAmount] = useState('');

  const isBuy = type === 'buy';

  const bigBalance = useBalance(isBuy ? pumpSdk.quote_address : pool.token_address);

  const decimalsA = isBuy && isExact ? pumpSdk.quote_decimals : pool.token_metadata.decimals;
  const decimalsB = !(isBuy && isExact) ? pumpSdk.quote_decimals : pool.token_metadata.decimals;

  useDebounce(
    () => {
      setFinalAmount(amount);
    },
    100,
    [amount],
  );

  const receive = usePumpCalculateTokenAndThresholdAmount({
    coinType: pool.token_address,
    amount: finalAmount ? new Decimal(finalAmount).mul(10 ** decimalsA).toString() : '',
    threshold: slippage,
    atob: isBuy,
    isExact,
  });

  const receiveAmount = new Decimal(
    receive?.tokenAmount && new Decimal(receive?.tokenAmount).gt(0) ? receive.tokenAmount : '0',
  )
    .div(10 ** decimalsB)
    .toString();

  const balance = new Decimal(bigBalance || 0)
    .div(10 ** (isBuy ? pumpSdk.quote_decimals : pumpSdk.token_decimals))
    .toString();

  const avaiableBalance = new Decimal(poolFields.real_token_reserves)
    .div(10 ** pool.token_metadata.decimals)
    .toString();

  const isOver = new Decimal(receiveAmount).gte(avaiableBalance);

  const handleTrade = async () => {
    if (!pool.token_address || !address || !amount || amount === '0') return;

    const symbolA = isBuy ? pumpSdk.quote_symbol : pool.symbol;
    const symbolB = !isBuy ? pumpSdk.quote_symbol : pool.symbol;

    const amountA = isExact ? amount : receiveAmount;
    const amountB = !!ratioAmount
      ? ratioAmount
      : isOver
      ? avaiableBalance
      : !isExact
      ? amount
      : receiveAmount;

    const ok = await transact(
      `Traded ${amountA} ${symbolA} for ${amountB} ${symbolB}`,
      async () => {
        if (type === 'buy') {
          return pumpSdk.buy({
            coinType: pool.token_address,
            amount: !!ratioAmount ? ratioAmount : isOver ? avaiableBalance : amount,
            isExact: !!ratioAmount ? false : isOver ? false : isExact,
            threshold: slippage,
            walletAddress: address,
          });
        } else {
          return pumpSdk.sell({
            coinType: pool.token_address,
            amount,
            isExact,
            threshold: slippage,
            walletAddress: address,
          });
        }
      },
    );

    ok && setAmount('');
  };

  let tipText = '';
  if (
    (address && isExact && amount && new Decimal(amount).gt(balance)) ||
    (address && !isExact && amount && receiveAmount && new Decimal(receiveAmount).gt(balance))
  ) {
    tipText = 'Insufficient balance';
  } else if (!isExact && amount && new Decimal(amount).gt(avaiableBalance)) {
    tipText = 'Exceeded the quantity available for purchase';
  }

  const disabled = !!(address && (!amount || !!tipText || receiveAmount === '0'));

  return (
    <div className={styles.buy_sell}>
      <div className={styles.tabs}>
        {types.map((item) => (
          <div
            key={item.label}
            className={item.label === type ? styles.active : ''}
            onClick={() => {
              setFinalAmount('');
              setType(item.label);
              setIsExact(true);
              setAmount('');
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.slippage_switch}>
        <Setting />
        {isBuy && (
          <div
            className={styles.switch}
            onClick={() => {
              setIsExact(!isExact);
              setAmount('');
            }}
          >
            switch to {(isExact && isBuy) || (!isExact && !isBuy) ? pool.symbol : 'SUI'}
          </div>
        )}
      </div>

      {isBuy ? (
        <Buy
          pool={pool}
          poolFields={poolFields}
          amount={amount}
          onChangeAmount={setAmount}
          atob={isBuy}
          isExact={isExact}
          balance={balance}
          tipText={tipText}
          avaiableBalance={avaiableBalance}
          receiveAmount={receiveAmount}
          isOver={isOver}
          slippage={slippage}
          onChangeRatioAmount={setRatioAmount}
        />
      ) : (
        <Sell
          pool={pool}
          amount={amount}
          onChangeAmount={setAmount}
          atob={isBuy}
          isExact={isExact}
          balance={balance}
          tipText={tipText}
          receiveAmount={receiveAmount}
        />
      )}

      <button
        className={`${styles.button} ${!isBuy ? styles.sell_btn : ''}`}
        onClick={address ? handleTrade : walletConnectModel.toggleDialog}
        disabled={disabled || (isBuy && isBlack)}
      >
        {address ? (
          (finalAmount || ratioAmount) && !receive ? (
            <Loading />
          ) : (
            'Trade'
          )
        ) : (
          'Connect Wallet'
        )}
      </button>
    </div>
  );
};

export default BuyAndSell;
