import { useState, type FC } from 'react';
import styles from './index.module.scss';
import PumpSearch from '../pump-search';
import { usePumpPools } from '@hooks/use-pump-pool';

import DataList from './list';
import Pagination from '@components/pagination';
import { type SortParams } from '@models/pump-pool.model';
import { useDebounce } from 'react-use';
import Loading from '@ui/loading';

const PumpList: FC = () => {
  const [reload, setReload] = useState(1);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<SortParams>('created_at');
  const [completed, setCompleted] = useState<boolean | undefined>(false);
  const [direction, setDirection] = useState<'desc' | 'asc'>('desc');

  useDebounce(
    () => {
      setSearch(searchName);
      setPage(1);
      setCompleted(!searchName ? false : undefined);
    },
    300,
    [searchName],
  );

  const limit = 24;
  const { pools, loading } = usePumpPools({
    search,
    sort,
    completed,
    page,
    reload,
    direction,
    pageSize: limit,
  });

  return (
    <div className={styles.wrapper}>
      <PumpSearch
        onSearch={setSearchName}
        sort={sort}
        onChangePage={setPage}
        onSortChange={setSort}
        completed={completed}
        onCompletedChange={setCompleted}
        onChangeReload={setReload}
        direction={direction}
        onChangeDirection={setDirection}
      />
      {loading ? (
        <div className={styles.loading}>
          <Loading size={48} />
        </div>
      ) : (
        <>
          <div className={styles.items}>
            {pools.data.map((pool) => {
              // if (
              //   pool.token_address ===
              //   '0xdccfc5587c91fac49cd2124c96449c4f0d68998a1d22b8ecf031ebe8697efa4b::acat::ACAT'
              // ) {
              //   return null;
              // }
              return <DataList pool={pool} key={pool.pool_id} />;
            })}
          </div>

          {pools.data.length > 0 && pools.total > limit && (
            <Pagination onChange={setPage} page={page} limit={limit} total={pools.total} />
          )}
        </>
      )}
    </div>
  );
};

export default PumpList;
